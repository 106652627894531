.stage-element {
    position: relative;
    /*
    height: calc(50vh - 100px);
    @include media-breakpoint-up(lg) {
        height: calc(50vh - 120px);
    }
    */
    height: 440px; 
}

.stage-backgroundimage-container,
.stage-backgroundimage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center center;
    background-size: cover;
    z-index: 1;
}

.stage-bg-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(59,59,59,.5);
    z-index: 2;
}
.stage-content {
    z-index: 2;
    position: relative;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-start;    
    color: #fff;
    padding-bottom: 40px;
    @include media-breakpoint-up(lg) {
        padding-bottom: 60px;
    } 
    .stage-text-inner {
        @extend .container;                
        h2 {
            color: #fff;
            max-width: 960px;
            font-size: 32px;
            margin-bottom: 30px;
            @include media-breakpoint-up(lg) {
                font-size: 48px;                            
            }
        }
    }
}        