.footer-contact-cta {
    background: $primary;
    color: $beige; 
    text-align: center;
    .frame {
        padding-top: 45px;
        padding-bottom: 45px;
    }            

    p {
        font-family: $headings-font-family;
        font-size: 28px;
        line-height: 1.375;    
        hyphens: none;    
        @include media-breakpoint-up(lg) {
            br {
                display: none;
            }
        }
    }
    
    a.btn {
        margin: 20px 0 0;
        @include media-breakpoint-up(lg) {
            margin: 0 20px;
        }
        border-color: #fff;
        background-color: #fff;
        color: $primary;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 21px;
        padding-bottom: 10px;
        &:hover {
            background: $primary;
            color: #fff;
        }
    }
}

.footer-text {
    h4 {
        margin-bottom: 30px;
    }
}

.footer-separator,
.footer-content,
.footer-meta {
    background: $lightgrey;
    h4 {
        text-transform: uppercase;
    }
}

.footer-separator {
    hr {
        margin: 0;
        border-top: 1px solid $border-color;
    }
}

.footer-meta {
    padding: 40px 0;
    .footer-copyright {
        font-size: 12px;
        line-height: 24px;
        text-transform: uppercase;
        color: $grey;
        text-align: center;
        padding-bottom: 20px;
        letter-spacing: .6px;
        @include media-breakpoint-up(lg) {
            text-align: left;
            padding-bottom: 0;
        }
    }
}

.footer-meta-nav ul {
    @include reset-list;
    text-align: center;
    @include media-breakpoint-up(lg) {
        text-align: right;
    }
    li {
        display: block;
        margin: 0 20px;
        padding-bottom: 20px;
        &:last-child {
            padding-bottom: 0;
        }
        @include media-breakpoint-up(lg) {
            display: inline-block;
            margin: 0 0 0 40px;
            padding-bottom: 0;
        }
        
        a {
            display: inline-block;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            color: $body-color;
            &:hover {
                color: $brown;
                text-decoration: none;
            }
        }
    }
}

.footer-content {
    padding: 60px 0;
    .col-12 {
        .frame {
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }

    }
}

.footer-social-media {
    h4 {
        margin-bottom: 30px;        
    }
}

.sociallinks-list {
    @include reset-list;
    margin-left: -5px;
    margin-right: -5px;
    li {
        display: inline-block;
        padding: 0 5px;
        a {
            @extend .btn;                
            height: 52px;
            width: 52px;
            border-radius: 50%;
            border: 2px solid $darkgrey;
            color: $darkgrey;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;            
            padding: 0;
            .icon {
                margin: 0;
            }
            &:hover {
                color: #fff;
                background-color: $darkgrey;
                text-decoration: none;
            }
        }
    }
}

.footer-contact {
    margin-bottom: -18px;
    h4 {
        margin-bottom: 30px;        
    }
    padding-top: 50px;
    padding-bottom: 50px;
    @include media-breakpoint-up(lg) {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    a {
        
    }
}

.texticon.texticon-left {
    display: flex;
    margin-bottom: 18px;
    .texticon-icon  {
        flex: 0 0 62px;
        .texticon-inner-icon {
            height: 52px;
            width: 52px;
            border-radius: 50%;
            border: 2px solid $light-brown;
            color: $light-brown;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;            
            padding: 0;
            //transition: all 0.15s ease-in-out;
            @include transition($btn-transition);
        }
    }
    .texticon-content {
        flex: 1 1 auto;
        padding-top: 16px;
        h5 {
            font-weight: $font-weight-medium;
            margin-bottom: 8px;
        }       
        color: $darkbrown;
        text-decoration: none;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
a:hover {
    .texticon.texticon-left {        
        .texticon-icon  {            
            .texticon-inner-icon {
                height: 52px;
                width: 52px;
                border-radius: 50%;
                background: $light-brown;
                color: #fff;
            }
        }
    }    
    text-decoration: none;    
}