.swiper-container {
    .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        border: 1px solid #fff;
        background: transparent;
        opacity: 0.5;
        padding: 1px;
        span {            
            display: block;
            border-radius: 100%;
            background-color: #fff;
            width: 10px;
            height: 10px;
        }
        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    z-index: 10;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    opacity: .5;
    @include transition($btn-transition);
    &:hover {
        opacity: 1;
    }
    &:after {
        display: none;
    }
}

.swiper-fullscreen-container {
    .swiper-button-prev,
    .swiper-button-next {
        @include media-breakpoint-down(sm) {
            display: none;
            
        } 
    }
}

.frame-type-carousel {
    padding: 0;
    .frame-container {
        max-width: 100%;
        padding: 0;
    }
    .stage-single-wrapper,
    .swiper-container {
        position: relative;
        height: calc(100vh - 100px);
        @include media-breakpoint-up(lg) {
            height: calc(100vh - 120px);
        }    
        .swiper-slide {
            .slide-backgroundimage-container
            .slide-backgroundimage {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-position: center center;
                background-size: cover;
                z-index: 1;
            }
            .slide-bg-overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(59,59,59,.5);
                z-index: 2;
            }
            .slide-content {
                z-index: 2;
                position: relative;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #fff;
                .slide-text-inner {
                    @extend .container;
                    @include media-breakpoint-up(md) {
                        max-width: 680px;
                    }
                    @include media-breakpoint-up(lg) {
                        margin-top: 5%;
                        max-width: 960px;
                    }
                    h5, h2 {
                        color: #fff;
                    }
                    
                    h2 {
                        font-size: 32px;
                        margin-bottom: 30px;
                        @include media-breakpoint-up(lg) {
                            font-size: 48px;                            
                        }
                    }
                    .slide-bodytext {
                        font-size: 16px;
                        font-weight: $font-weight-medium;
                        @include media-breakpoint-up(lg) {
                            font-size: 20px;                            
                        }
                    }
                    h5 {
                        height: 60px;
                        position: relative;
                        padding-top: 32px;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        svg {
                            position: absolute;
                            left: 50%;
                            top: 0;
                            margin-left: -60px;
                            height: 60px;
                            width: 120px;
                            fill: #fff;
                            opacity: .4;                            
                        }
                    }
                }
            }
        }
    }
}

.swiper-advisors .swiper-advisors-slides-container,
.frame-type-references .swiper-multi-slides-container {
    margin-left: 8.33333%;
    margin-right: 8.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    width: 83.33333%;
    position: relative;
    .swiper-slide {
        text-align: center;
        .swiper-slide-wrap {
            padding: 25px;
            margin: 10px;
            border: 1px solid $silvergrey;
            border-radius: 5px;
            height: 300px;
            display: flex;
            align-items: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    
}

.swiper-advisors,
.frame-type-references .frame-content {
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
        font-size: 28px;
        color: $body-color;        
    }
    .swiper-button-prev {
        left: 15px;        
    }
    .swiper-button-next {
        right: 15px;
    }
}