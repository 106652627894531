.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  

  .icon-twitter {
    width: 0.9285714285714285em;
  }
  
  .icon-facebook {
    width: 0.5881428571428571em;
  }
  
  .icon-linkedin {
    width: 0.8571428571428571em;
  }
  
  .icon-xing {
    width: 0.7857142857142857em;
  }
  .icon-world {
    width: 1.0em;
  }
  .icon-instagram {
    width: 0.8571428571428571em;
  }
  
  .icon-vk {
    width: 1.092642857142857em;
  }
  
  .icon-vimeo {
    width: 1.005em;
  }
  
  .icon-map-marker {
    width: 0.5714285714285714em;
  }

  .icon-chevron-right {
    width: 0.6785714285714285em;
  }

  .icon-chevron-left {
    width: 0.75em;
  }
  
  .icon-phone {
    width: 0.7857142857142857em;
  }

  .icon-download {
    width: 21px;
    height: 21px;
  }

.btn {
  .icon-download {
    margin-left: 8px;
  }
}  