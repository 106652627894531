.page-header {
    padding: 0px;
    background: #fff;
    .container {        
        padding: 0 20px;
        @include media-breakpoint-up(lg) {
            height: 120px;
        }
    }    

    @include media-breakpoint-down(md) {
        position: relative;
    }

    .navbar-brand {
        display: block;
        flex: 0 0 auto;
        margin-right: auto;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
        @include media-breakpoint-down(md) {
            padding-top: 22px;
            padding-bottom: 23px;
        }
    }

    .navbar-toggler {
        outline: none;
        margin: 0;
        margin-right: -12px;
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;
            svg {
                width: 27px;
                height: 16px;
            }
        }    
    }
}

.body-wrap {    
    @include media-breakpoint-up(lg) {
        padding-top: 120px;
    }
}

#mainnavigation { 
    @include media-breakpoint-up(lg) {   
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        align-items: flex-end;
    }
    @include media-breakpoint-down(md) {
        padding: 0 20px 30px;
    }
    .language-menu {
        @include reset-list;
        font-weight: $font-weight-bold;
        font-size: 13px;
        line-height: 18px;
        padding-top: 10px;
        padding-bottom: 10px;
        @include media-breakpoint-down(md) {
            text-align: right;
        }
        li {
            display: inline-block;
            padding: 0 5px;
            a {
                color: $light-brown;
                opacity: .5;            
            }
            &.active {
                a {
                    color: $body-color;
                    opacity: 1;
                }
            }

            &.text-muted {
                color: $light-brown;
                opacity: .5;  
            }
        }    
    }
    .navbar-nav {
        align-items: center;
        padding: 10px 0;

        a {
            text-transform: uppercase;
            position: relative;
            font-size: 14px;
            padding: .5rem 1rem;
            font-weight: $font-weight-medium;
            color: $body-color;
            @include media-breakpoint-up(lg) {   
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 1rem;
                    right: 1rem;                
                    height: 2px;
                    background-color: transparent;
                }
                &:hover {                    
                    &::after {
                        background-color: $primary;
                    }
                }
            }    
            &:hover {
                color: $primary;                
            }
        }
        li {
            &.active a {
                color: $primary;
                @include media-breakpoint-up(lg) {   
                    &::after {
                        background-color: $primary;
                    }
                }    
            }
            &:last-child {
                padding-left: 1rem;
                a {                    
                    border: 2px solid $primary;
                    padding: 11px 28px;
                    color: $primary;
                    line-height: 18px;
                    font-size: 14px;
                    border-radius: 45px;
                    &::after {
                        content: none;
                    }
                    &:hover {
                        background: $primary;
                        color: #fff;
                    }
                }
                &.active a {
                    background: $primary;
                    color: #fff;
                }
            }
        }
        @include media-breakpoint-down(md) {
            padding-top: 0;
            align-items: flex-start;
            li {                
                padding: 6px 0;
                &:last-child {
                    padding-left: 0;
                }    
            }
            a {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}