@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "variables.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
//@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "~swiper/swiper";

@import "mixins";
@import "base";
@import "types";

@import "buttons";
@import "icons";

@import "frames";

@import "lightbox";
@import "header";
@import "footer";
@import "stage";

@import "section-header";
@import "button-teaser";

@import "swiper-slider";
@import "counter";

@import "card";
@import "textpic";

@import "skills";
@import "advisors";
@import "news";
@import "references";
@import "tabs";

@import "form";


// TODO
#map-canvas {
  height: 50vh;
  color: black;
}

.extbase-debugger-floating {
  z-index: 9999;
}

.advisors-list-component li.active .nav-link {
  font-weight: bold;
  color: black;
}