@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin shadow {
    //box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.25);
}


@mixin input-placeholder($color) {
    &.placeholder {
      color: $color;
    }
    &:-moz-placeholder {
      color: $color;
    }
    &::-moz-placeholder {
      color: $color;
    }
    &:-ms-input-placeholder {
      color: $color;
    }
    &::-webkit-input-placeholder {
      color: $color;
    }
}
