.reference-list {
    .frame-content {
        margin-bottom: -30px;
    }
    .col {
        padding-bottom: 30px;
    }
    .card {
        min-height: 260px;
        text-align: center;
        background: #fff;
        a {
            height: 100%;
            display: flex;
            flex-direction: column; 
            .card-overlay {
                .overlay-icon {
                    svg.icon-external-link {
                        width: 27px;
                        height: 27px;
                    }
                }
            }                   
        }
        .reference-logo {
            flex: 1 1 auto;
            padding: 25px;
            display: flex;
            justify-items: center;
            align-items: center;
            img {
                margin: 0 auto;
            }
        }
        .reference-text {
            flex: 0 0 auto;
            padding: 0px 25px 25px;
            h4 {
                margin-bottom: 8px;
                color: $brown;
            }
            .services {
                color: $light-brown;
                text-transform: uppercase;
                font-size: 12px;
                font-weight: $font-weight-bold;
            }
        }
    }
}