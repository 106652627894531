.footer-news-cta {
    text-align: center;
    padding-top: 60px;
    p {
        font-family: $headings-font-family;
        font-weight: $font-weight-bold;
        font-size: 20px;
        line-height: 1.375;        
        @include media-breakpoint-up(lg) {
            br {
                display: none;
            }
        }
    }
    
    a.btn {
        margin: 20px 0 0;
        @include media-breakpoint-up(lg) {
            margin: 0 20px;
        }
        border-color: $primary;
        background-color: #fff;
        color: $primary;
        font-family: $font-family-base;
        font-size: 16px;
        line-height: 21px;
        padding-bottom: 10px;
        &:hover {
            background: $primary;
            color: #fff;
        }
    }
}

.news-list-view-index,
.news-list-view {
    margin-bottom: -30px !important;
    > .col-12 {
        margin-bottom: 30px;
    }
    .card a .card-body {
        div.preline {
            font-size: 12px;
            text-transform: uppercase;
            color: $light-brown !important;
            margin-bottom: 10px;
        }
        h4 {
            margin-bottom: 10px;
        }
    }    
    .teaser-text {
        margin-bottom: 22px;
    }
    p.readmore-link {
        font-weight: $font-weight-bold;
        font-size: 14px;
        display: flex;        
        text-transform: uppercase;
        align-items: flex-start;
        span {
            flex: 0 0 auto;
        }
        .readmore-icon {
            margin-left: 8px;
            line-height: 16px;
        }
    }  
}

.news-stage {    
    .news-stage-element {
        position: relative;
        height: calc(100vh - 100px);
        @include media-breakpoint-up(lg) {
            height: calc(100vh - 120px);
        } 
        .news-backgroundimage-container,
        .news-backgroundimage {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-position: center center;
            background-size: cover;
            z-index: 1;
        }
    
        .stage-bg-overlay {
            position: absolute;
            top: 50%;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.65;
            background-color: transparent;
            background-image: linear-gradient(180deg, rgba(69,62,55,0) 0%, #000000 100%);            
            z-index: 2;
        }
        .news-stage-content {
            z-index: 2;
            position: relative;
            display: flex;
            height: 100%;
            align-items: flex-end;
            padding-bottom: 40px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 60px;
            }    
            justify-content: flex-start;
            color: #fff;
            .stage-text-inner {
                @extend .container;                
                @include media-breakpoint-up(lg) {
                    
                }
                h5, h2 {
                    color: #fff;
                    max-width: 960px;
                }
                
                h2 {
                    font-size: 32px;
                    margin-bottom: 30px;
                    @include media-breakpoint-up(lg) {
                        font-size: 48px;                            
                    }
                }
                h5 {
                    margin-bottom: 10px;
                }
                .slide-bodytext {
                    font-size: 16px;
                    font-weight: $font-weight-medium;
                    @include media-breakpoint-up(lg) {
                        font-size: 20px;                            
                    }
                }
                h5 {
                    height: 60px;
                    position: relative;
                    padding-top: 32px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    svg {
                        position: absolute;
                        left: 50%;
                        top: 0;
                        margin-left: -60px;
                        height: 60px;
                        width: 120px;
                        fill: #fff;
                        opacity: .4;                            
                    }
                }
            }
        }
    }
}

.news-text-wrap {
    margin-bottom: 60px;
    > *:last-child {
        margin-bottom: 0;
    }
}

.news-img-wrap {
    .mediaelement {
        margin-bottom: 30px;
        a.lightbox {
            position: relative;
            z-index: 1;
        }
    }
}

.news-backlink-wrap {
    padding-top: 60px;
    a {
        font-weight: $font-weight-bold;
        font-size: 14px;
        display: inline-block;
        color: $body-color;
        text-transform: uppercase;
    }
}

.frame-type-news-detail {
    .info-column {
        .author-info {
            img {
                margin-bottom: 18px;
            }
            p {
                font-size: 14px;
                font-weight: $font-weight-medium;
            }
            .author-portrait {
                margin-bottom: 28px;
            }
        }
        .news-list-category {
            @include reset-list;
            li {
                font-weight: $font-weight-medium;
                color: $primary;
                margin-bottom: 18px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
		.news-detail-latest {
            .news-list-items {

                .meta {
                    color: $light-brown;                    
                    text-transform: uppercase;
                    font-size: 12px;
                }
                a {
                    display: flex;
                    margin-bottom: 20px;
                    font-weight: $font-weight-medium;
                }
                .list-image {
                    flex: 0 0 130px;                    
                }
                h5 {
                    font-size: 14px;
                }
            }
        }
    }
    hr {
        margin: 40px 0;
        border-color: $silvergrey;
    }
}

.frame-type-news-categories {
    padding-top: 32px;
    padding-bottom: 32px;    
    overflow: auto;
    
    ul {
        @include reset-list;   
        display: flex;     
        flex-wrap: nowrap;
        li {
            padding-right: 30px;
            flex: 0 0 auto;
            a {
                color: $glacier;
                font-size: 14px;
                line-height: 36px;
                font-weight: $font-weight-bold;
                position: relative;
                padding: 0;
                display: block;
                &:after {
                content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;                    
                    height: 1px;                    
                }
                &.active,
                &:hover {
                    color: #fff;
                    &:after {
                        background: #fff;
                    }
                }
            }
        }
    }
}    

.news-list-view-index .topnews  a {
    width: 100%;
    display: flex;    
    flex: 0 0 100%;
    max-width: 100%;
    background-color: $primary;
    border-radius: $border-radius;
    overflow: hidden;
    .row {
        align-items: center;
        .col-lg-7 {
            img {
                width: 100%;
                height: auto;
            }            
            @include media-breakpoint-up(lg) {
                padding-right: 15px;
                border-radius: $border-radius 0 0 $border-radius;
                overflow: hidden;
            }                
        }
        .col-lg-5 {
            padding: 30px;
            @include media-breakpoint-up(lg) {
                padding: 30px 30px 30px 15px;
            }
        }
    }
    .preline {
        color: $glacier;                    
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px;
    }
    h3 {
        color: $beige;
        font-size: 28px;
        margin-bottom: 16px;
    }
    p {
        color: #fff;
        margin-bottom: 16px;
    }
    p.readmore-link {
        color: $beige;
    }
}

.frame-type-news {
    
}

.maincolumn {
    .frame {
        padding: 0;
        margin-top: 0 !important;
        margin-bottom: 30px !important;
        
        &:first-child {
            margin-top: 0;
        }
    }
}

.news-list-view.masonary-sort {
    column-count: 3;
    column-gap: 30px;
    @include media-breakpoint-down(md) {
        column-count: 2;
    }

    @include media-breakpoint-down(sm) {
        column-count: 1;            
    }
    .article {
        margin-bottom: 30px;
        break-inside: avoid;
        page-break-inside: avoid;
        page-break-before: avoid;
        overflow: hidden;
    }
}