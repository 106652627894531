.advisors-list-component .skills-filter {
    .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    
    .filter-toggle {
        a {
            color: #fff;
        }
        svg {
            margin-top: -3px;
            margin-right: 10px;
            transform: rotate( 0deg );            
            transition: transform 150ms ease;
        }
        .collapsed {
            svg {
                transform: rotate( -180deg );                            
            }
        }
    }
    #filter-block {
        .row {
            padding-top: 50px;
            .col-12 {
                margin-bottom: 45px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }
        
    }
    .reset-filter {
        margin-top: -24px;
        float: right;
        button {
            padding: 0;
            margin: 0;
            background: transparent;
            color: #fff;
            border: 0;
            outline: 0;
            font-size: 14px;
            font-weight: $font-weight-bold;
        }
    }
    .filter-header {
        @include media-breakpoint-up(lg) {
            min-height: 92px;
        }    
    }
    h4 {
        color: $beige;
        hyphens: none;
    }
    ul {
        padding: 0;
        li {
            padding: 15px 0;
            display: block;
            a {
                color: #fff;
                padding: 0;
                line-height: 25px;
                display: flex;
                align-items: flex-start;
                .filter-item-icon {
                    width: 25px;
                    height: 25px;
                    flex: 0 0 25px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid #fff;
                    border-radius: 6px;
                    svg {
                        width: 17px;
                        height: 16px;
                        opacity: 0;
                        transition: $all-transition;
                    }
                }
                &:hover {
                    text-decoration: none;
                    &:after {
                        display: none;
                    }
                }
            }
            &.active {
                a {
                    .filter-item-icon {                        
                        svg {
                            opacity: 1;
                        }
                    }
                }        
            }
        }
    }
}    

.advisors-list-component {
    .frame-content {
        margin-bottom: -30px;
    }
    .col {
        padding-bottom: 30px;
    }
}    

.swiper-advisors-slides-container,
.advisors-list-component {
    .card {
        background: $primary;
        text-align: center;
        h4 {
            color: $beige;
            span {
                display: block;
                text-transform: uppercase;
                font-size: 12px;
                margin-bottom: 5px;
                color: $beige;
            }
        }
        
        p {
            font-size: 12px;
            font-weight: $font-weight-medium;
            color: $glacier;    
        } 

    }
}

.advisors-detail-stage {    
    position: relative;
    height: calc(100vh - 100px);
    @include media-breakpoint-up(lg) {
        height: calc(100vh - 120px);
    }    
    .stage-backgroundimage-container,
    .stage-backgroundimage {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: top center;
        background-size: cover;
        z-index: 1;        
    }
    .stage-bg-overlay {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.65;
        background-color: transparent;
        background-image: linear-gradient(180deg, rgba(69,62,55,0) 0%, #000000 100%);            
        z-index: 2;
    } 
    .stage-content {
        z-index: 2;
        position: relative;
        display: flex;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-start;        
        color: #fff;
        .stage-text-inner {
            @extend .container;
            padding-bottom: 40px;
            @include media-breakpoint-up(lg) {
                padding-bottom: 50px;
            }
            h1 {
                color: #fff;
                font-size: 32px;
                margin: 0;
                padding: 0;
                @include media-breakpoint-up(lg) {
                    font-size: 48px;                            
                }
            }            
        }
    }
}

.top-contact-bar,
.frame-type-advisors_contact {
    .contact-icon {
        a {
            color: #fff;
            display: flex;
            align-items: center;
            .texticon-icon {
                flex: 0 0 42px;
                height: 42px;
                width: 42px;
                margin-right: 10px;
                border-radius: 50%;
                border: 2px solid #fff;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;            
                padding: 0;                
                @include transition($btn-transition);
            }
            &:hover {
                .texticon-icon {
                    color: $primary;
                    background-color: #fff;                        
                }    
            }
        }
    }
    .advisor-social-media {
        .sociallinks-list {
            li {
                a {
                    height: 42px;
                    width: 42px;
                    border: 2px solid #fff;
                    color: #fff;
                    font-size: 20px;                    
                    &:hover {
                        color: $primary;
                        background-color: #fff;                        
                    }
                }
            }
        }
    }
}

.top-contact-bar {
    background-color: $primary;
    .container {
        padding-top: 29px;
        padding-bottom: 29px;
    }
    .contact-icon {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            flex: 0 0 auto;
        }        
    }
    .advisor-social-media {
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
        flex: 0 0 100%;
        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            margin-left: auto;        
        }
    }
}

.tx-advisor .frame-type-text {
    ul {
        @extend .list-normal;
    }
}

.frame-type-introtext {
    p {
        @extend .lead;
    }
}

.frame-type-advisors_news {
    .frame-content {
        margin-top: 40px;
        background-color: $secondary;
        border-radius: $border-radius;
        overflow: hidden;
        .row {
            align-items: center;
            .col-lg-7 {
                overflow: hidden;
                img {
                    width: 100%;
                    height: auto;                    
                }
            
                @include media-breakpoint-up(lg) {
                    padding-right: 15px;
                    border-radius: $border-radius 0 0 $border-radius;    
                }
            }
            .col-lg-5 {
                padding: 30px;
                @include media-breakpoint-up(lg) {
                    padding: 30px 30px 30px 15px
                }
            }
        }
        .preline {
            color: $light-brown;                    
            text-transform: uppercase;
            font-size: 12px;
            margin-bottom: 10px;
        }
        a {
            font-weight: $font-weight-bold;
            font-size: 14px;
            display: inline-block;
            color: $body-color;
            text-transform: uppercase;
        }
    }    
}

.advisor-quote {
    padding-bottom: 50px;
    text-align: center;
    color: $beige;
    font-style: italic;
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    font-size: 24px;
}

.frame-type-advisors_contact {    
    padding-bottom: 60px;
    h4 {
        color: #fff;
    }
    .contact-icon {
        padding-bottom: 20px;
    }
    .advisor-social-media { 
        padding-bottom: 20px;
        margin-bottom: 20px;    
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50px;
            height: 1px;
            background: $glacier;
        }
    }
    img {
        margin-bottom: 65px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

.swiper-advisors-slides-container {
    position: relative;
    .swiper-wrapper {
        align-items: stretch;
        .swiper-slide {
            padding-left: 15px;
            padding-right: 15px;
            height: auto;
        }
    }
}