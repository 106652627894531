.frame-type-buttonteaser {
    text-align: center;
    .frame-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
            flex-direction: row;
        }    
    }
    p {
        &.teaser-text {
            font-family: $headings-font-family;
            font-size: 24px;
            line-height: 1.375;  
            font-weight: $font-weight-bold;      
        }    
        @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
            padding: 0 10px; 
            margin-bottom: 0;
            &.teaser-text {
                line-height: 45px;
            }       
        }
    }
}

.rawframe-type-list {
    & + .frame-type-buttonteaser {
        padding-top: 60px;
    }
}