
.custom-control {
    &.custom-radio {
        padding-left: 0;
        > .custom-control-label {                
            padding: 0 16px 0 36px;
            line-height: 25px;
            
            &::before {                
                top: 0px;
                left: 0px;
                width: 25px;
                height: 25px;                
                border: 2px solid $brown;
            }
            &::after {                
                top: 6px;
                left: 6px;
                width: 13px;
                height: 13px;                
            }
        }
        > .custom-control-input:checked~.custom-control-label {
            &::after {
                background: $brown;
            }
        }        
    }
    &.custom-checkbox {
        padding-left: 0;
        padding-top: 45px;
        padding-bottom: 30px;
        > .custom-control-label {                
            padding: 0 16px 0 36px;
            line-height: 25px;
            
            &::before {                
                top: 0px;
                left: 0px;
                width: 25px;
                height: 25px;                
                border: 2px solid $brown;
                border-radius: 5px;
            }
            &::after {                
                top: 6px;
                left: 6px;
                width: 13px;
                height: 13px;  
            }
        }    
        > .custom-control-input:checked~.custom-control-label {
            &::before {
                background: transparent;
            }
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.642' height='15.376' viewBox='0 0 16.642 15.376'%3E%3Cpath d='M469.592,840.535l5.922,3.948,7.958-13' transform='translate(-468.205 -830.107)' fill='none' stroke='%23786452' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E%3C/svg%3E%0A");
                background-size: 100% 100%;
            }
        }    
    }
}

.frame-type-form_formframework {
    header {
        margin-bottom: 40px;
    }
    .form-row {
        margin-left: -15px;
        margin-right: -15px;
        padding-top: 15px;
        > .col, 
        > [class*="col-"] {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.form-group {
    &.select-input,
    &.textarea-input,
    &.phone-input,
    &.email-input,
    &.text-input {
        position: relative;
        label {
            color: $light-brown;
            position: absolute;
            top: 0;
            left: 10px;
            font-size: 1rem;
            cursor: text;
            transition: color .2s ease-out, -webkit-transform .2s ease-out;
            transition: transform .2s ease-out, color .2s ease-out;
            transform-origin: 0% 100%;
            -webkit-transform: translateY(12px);
            transform: translateY(12px);
            margin: 0;
        }
        &.select-input,
        &.is-completed,
        &.is-active {
            label {
                transform: translateY(-14px) scale(0.8);
            }
        }
        .form-control {
            display: block;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $body-color;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid $silvergrey;
            border-radius: 0;
            outline: none;
            height: 3rem;
            width: 100%;            
            margin: 0 0 8px 0;
            padding: 0 10px;
            box-shadow: none;
            transition: border .3s, -webkit-box-shadow .3s;            
        }
        &.textarea-input {
            .form-control {
                height: 6rem;
                padding-top: 8px;
            }    
        }
        &.select-input {
            .form-control {
                padding-left: 20px;
            }
        }
    }
}

.actions {
    .btn-group {
        text-align: right;
    }
}