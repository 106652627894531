.ce-textpic.ce-center {
    .ce-column {
        text-align: center;
    }
}

.ce-textpic {
    .image-embed-item {
        max-width: 100%;
        height: auto;
    }
    &.ce-left.ce-intext.ce-nowrap {
        display: flex;
        flex-wrap: nowrap;
        .ce-gallery {
            flex: 0 0 auto;
            margin-right: 30px;
        }
        .ce-bodytext {
            flex: 1 1 auto;
        }
    } 
}

.ce-textpic {
    &.ce-left.ce-intext {
        .ce-gallery {
            float:left;
            margin-right: 30px;
            margin-bottom: 30px;
        }        
    }
    &.ce-right.ce-intext {
        .ce-gallery {
            float:right;
            margin-left: 30px;
            margin-bottom: 30px;
        }
        &.ce-nowrap {
            display: flex;
            flex-wrap: nowrap;
            .ce-gallery {
                order: 2;
                flex: 0 0 auto;
                float: none;
            }
            .ce-bodytext {
                order: 1;
                flex: 1 1 auto;
            }
        } 
    }
}

.ce-textpic.ce-below {
    .ce-bodytext {
        margin-bottom: 30px;
        p:last-child {
            margin-bottom: 0;
        }
    }
}

.frame-type-image {
    img {
        max-width: 100%;
        height: auto;
    }
}