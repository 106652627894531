.section-header {
    position: relative;
    text-align: center;
    .section-header-icon {
        position: absolute;
        top: 0;
        width: 100%;
        color: $creme;
        z-index: 1;
        svg {
            height: 100%;
            width: auto;
        }
    }
    &.aaa-icon {
        .section-header-icon {
            height: 60px;
        }    
    }
    &.network-icon {
        color: #fff;
        .section-header-icon {    
            height: 100px;        
            svg {
                height: 100px;
                width: 100px;
            }
            svg {
                stroke: currentColor;
            }
        }    
    }
    &.diagram-icon {
        color: #fff;
        .section-header-icon {    
            height: 93px;        
            svg {
                height: 93px;
                width: 102px;
            }
            svg {
                stroke: currentColor;
            }
        }    
    }

    h2 {
        font-size: 28px;
        font-family: $font-family-base;
        font-weight: $font-weight-medium;
        margin-bottom: 0px;
        z-index: 2;
        color: $brown;
        text-transform: uppercase;
        position: relative;
        @include media-breakpoint-up(lg) {
    
        }
    }

    h5 {
        font-size: 12px;
        line-height: 16px;
        font-weight: $font-weight-bold;
        letter-spacing: 2px;  
        position: relative;
        z-index: 10;
        color: $light-brown;   
        text-transform: uppercase;   
        padding: 32px 0 12px;
        margin: 0;
    }
    .teaser-text {
        padding: 40px 0 20px;
        z-index: 2;
        position: relative;
        p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.frame-type-sectionheader {
    position: relative;
    .container-backgroundimage-container,
    .container-backgroundimage {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center center;
        background-size: cover;
        z-index: 1;
    }
    .container-bg-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 2;
    }
    &.frame-has-backgroundimage {

    }
}


.frame-background-secondary {
    .section-header {
        .section-header-icon {
            color: #fff;
        }
    }    
}

.frame-type-sectionheader {
    padding-bottom: 40px;
    & + .frame {
       
    }
    &.frame-has-backgroundimage {
        padding-top: 200px;
        padding-bottom: 200px;
        .section-header {
            z-index: 10;
            h2, h5 {
                color: #fff;
            }
            h2 {
                text-transform: none;
            }
            .section-header-icon {
                opacity: .25;
            }
        }    
    }
}

