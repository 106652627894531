.skills-filter {
    background: $primary;
    overflow: auto;
    ul {
        padding-top: 32px;
        padding-bottom: 32px;
        li {
            padding-right: 30px;
            display: inline-block;
            a {
                color: $glacier;
                font-size: 14px;
                line-height: 36px;
                font-weight: $font-weight-bold;
                position: relative;
                padding: 0;
                &:after {
                content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;                    
                    height: 1px;                    
                }
                &.active,
                &:hover {
                    color: #fff;
                    &:after {
                        background: #fff;
                    }
                }
            }
        }
    }
}

.skills-component {
    min-height: 590px;
    .skills-filter {
        ul {
            display: flex;                
            flex-wrap: nowrap;
            li {                
                flex: 0 0 auto;
            }
        }        
    }
}
.frame-type-skill-list {
    .frame-content {
        margin-bottom: -30px;
    }
    .col {
        padding-bottom: 30px;
    }    
    .card {
        h3 {
            padding-bottom: 20px !important;
            margin-bottom: 20px !important;
        }
    }
}