.btn {
     text-transform: uppercase;
     .icon {
         margin-left: 8px;
     }
}

.btn-secondary {
    color: $primary;
    &:hover {
        background: $primary;
        border-color: $primary;
        color: $secondary;
    }
}

.btn-primary-outline {
    border-color: $primary;
    color: $primary;
    &:hover {
        background: $primary;
        color: #fff;
    }
}

.frame-special-container,
.frame-background-primary {
    .btn-primary-outline {
        border-color: $beige;
        color: $beige;
        &:hover {
            background: $beige;
            color: $primary;
        }
    }
}

.btn-white {
    background: #fff;
    border-color: #fff;
    color: $primary;
    &:hover {
        background: $primary;
        border-color: $primary;
        color: #fff;
    }
}



.btn-big {
    font-size: 16px;
    line-height: 31px;
}