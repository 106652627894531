$darkbrown: #453E37;
$brown: #786451;
$light-brown: #CFBAA3;
$beige: #FFF5E8;
$creme: #F5EFE8;

$teal:    #1F6786;
$glacier: #86B0C1;

$lightgrey: #F0F0F0;
$silvergrey:#B7B7B7;
$grey:	 	#7B7B7B;
$darkgrey: #3B3B3B;


$gray-100: $lightgrey;
$gray-500: $silvergrey;
$gray-600: $grey;
$gray-900: $darkgrey;

$primary: $teal;
$secondary: $creme;

$grid-gutter-width: 30px;

$font-family-base: 'Roboto';
$font-weight-base: 400;
$body-color: $darkbrown;

$font-weight-medium: 500;
$font-weight-bold: 700;

$headings-font-family: 'Arsenal';
$headings-font-weight: $font-weight-bold;
$headings-line-height:        1.2 !default;
$headings-color: $darkbrown;


$line-height-lg: 1.4;
$line-height-sm: 1.4;

$border-width:                1px !default;
$border-color:                $gray-600 !default;

$border-radius:               10px;
$border-radius-lg:            $border-radius;
$border-radius-sm:            $border-radius;


$btn-padding-y: 10px;
$btn-padding-x: 40px;
//$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               14px;
$btn-line-height:             21px;
//$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

//$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
//$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
//$btn-font-size-sm:            $input-btn-font-size-sm !default;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;

//$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
//$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
//$btn-font-size-lg:            $input-btn-font-size-lg !default;
//$btn-line-height-lg:          $input-btn-line-height-lg !default;

$btn-border-width: 2px;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow:  none;
$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow: none;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           45px;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
$all-transition:              all .15s ease-in-out;

$card-spacer-y: 30px;
$card-spacer-x: 30px;
$card-border-width: 0;
$card-border-radius: $border-radius;
//$card-border-color:                 rgba($black, .125) !default;
//$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-bg: $secondary;