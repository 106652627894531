.frame {
    padding-top: 80px;
    padding-bottom: 80px;

    header {
        
        
    }
    > .frame-container {
        @extend .container;
    }
    .col,
    .frame-content {
        > *:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    } 
    &.frame-type-html {
        padding-top: 2rem;
        padding-bottom: 0;
    }
}


.frame.frame-background-none:not(.frame-has-backgroundimage),
.rawframe.rawframe-background-none {
    &+ .frame.frame-background-none:not(.frame-has-backgroundimage),
    &+ .rawframe.rawframe-background-none {
        margin-top: -80px;
    }    
}

.frame-background-primary,
.rawframe-background-primary {
    background-color: $primary;
    &+ .frame-background-primary,
    &+ .rawframe-background-primary {
        margin-top: -80px;
    }
}

.frame-background-secondary,
.rawframe-background-secondary {
    background-color: $secondary;
    &+ .frame-background-secondary,
    &+ .rawframe-background-secondary {
        margin-top: -80px;
    }
}
.frame-background-beige,
.rawframe-background-beige {
    background-color: $beige;
    &+ .frame-background-beige,
    &+ .rawframe-background-beige {
        margin-top: -80px;
    }
}
.frame-background-grey {
    background-color: $grey;
    &+ .frame-background-grey {
        margin-top: -80px;
    }
}

.rawframe-background-primary {
    &.rawframe-type-list {
        .frame {
            background-color: $primary;
        }
    }
}

.rawframe-background-secondary {
    &.rawframe-type-list {
        .frame {
            background-color: $secondary;
        }
    }
}
.rawframe-background-beige {
    &.rawframe-type-list {
        .frame {
            background-color: $beige;
        }
    }
}


.container {
    @include media-breakpoint-down(sm) {
        padding-left: 20px;
        padding-right: 20px;
    }
    .frame-container {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        max-width: 100%;
    }
}

.frame-type-2cols-container {    
    .column-1 {
        @include media-breakpoint-down(md) {
            padding-bottom: 60px;
        }
        @extend .col-12;
        @extend .col-lg-6;
    }
    .column-2 {
        @extend .col-12;
        @extend .col-lg-6;
    }
    &.frame-layout-0,
    &.frame-layout-40 {
        .column-1 {
            @extend .col-lg-6;
        }
        .column-2 {
            @extend .col-lg-6;
        }
    }    
    &.frame-layout-20 {
        .column-1 {
            @extend .col-lg-9;
        }
        .column-2 {
            @extend .col-lg-3;
        }
    }
    &.frame-layout-30 {
        .column-1 {
            @extend .col-lg-8;
        }
        .column-2 {
            @extend .col-lg-4;
        }
    }
    &.frame-layout-50 {
        .column-1 {
            @extend .col-lg-5;
        }
        .column-2 {
            @extend .col-lg-7;
        }
    }
    &.frame-layout-60 {
        .column-1 {
            @extend .col-lg-4;
        }
        .column-2 {
            @extend .col-lg-8;
        }
    }
    &.frame-layout-70 {
        .column-1 {
            @extend .col-lg-3;
        }
        .column-2 {
            @extend .col-lg-9;
        }
    }
}

.frame-type-3cols-container {
    align-items: stretch;
    &.frame-layout-0 {
        .column-1 {
            @extend .col-12;
            @extend .col-lg-4;
            position: relative;
            @include media-breakpoint-down(md) {
                padding-bottom: 60px;
            }
        }
        .column-2 {
            @extend .col-12;
            @extend .col-lg-4;
            position: relative;
            @include media-breakpoint-down(md) {
                padding-bottom: 60px;
            }
        }
        .column-3 {
            @extend .col-12;
            @extend .col-lg-4;
            position: relative;
        }
    }
}

.frame-type-2cols-container, 
.frame-type-3cols-container {
    .column-1,
    .column-2,
    .column-3 {
        .frame:first-child {
            padding-top: 0;
        }
        .frame:last-child {
            padding-bottom: 0;
        }
        .frame-container {
            padding: 0;
        }
    }    
}

.frame-type-raw-container {
    .frame-content {
        .frame {
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

.frame-well .frame-inner {
    
    header {
        margin-bottom: 10px;
    }
    h3 {
        color: $red;
    }
    p, ul, li {
        
    }
}

.frame-special-container {
    background-color: $primary;    
    padding: 80px 30px 80px !important;
    border-radius: 10px 10px 0 0;
    color: $beige;
    margin-bottom: -80px;
    @include media-breakpoint-up(lg) {
        padding-top: 100px !important;
        padding: 100px 30px 50px;
        margin-top: -130px;        
        height: calc(100% + 210px);
    }    
    z-index: 1000;
    position: relative;
    -webkit-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 0px -3px 5px 0px rgba(0,0,0,0.5);
    h1, h2, h3, h4, h5 {
        color: $beige;
    }
}

.frame.frame-layout-3 {
    > .frame-container {
        > .frame-inner {
            @extend .row;
            header,
            .frame-content {
                @extend .col-12;
                @extend .col-lg-10;
                @extend .offset-lg-1;
            }
        }
    }        
}

.rawframe-advisor_projectmap {
    margin-top: 0 !important;
}