/* arsenal-regular - latin */
@font-face {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/arsenal-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/arsenal-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/arsenal-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/arsenal-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/arsenal-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/arsenal-v5-latin-regular.svg#Arsenal') format('svg'); /* Legacy iOS */
  }
  /* arsenal-700 - latin */
  @font-face {
    font-family: 'Arsenal';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/arsenal-v5-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/arsenal-v5-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/arsenal-v5-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/arsenal-v5-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/arsenal-v5-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/arsenal-v5-latin-700.svg#Arsenal') format('svg'); /* Legacy iOS */
  }
  
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-500 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  
  body {
    font-family: $font-family-base;
    font-size: 16px;
    -ms-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  
  h1, h2, h3, h4,
  .h1, .h2, .h3, .h4 {
  
  }
  
h3, .h3 {
    font-size: 24px;
    line-height: 30px;
}

h4, .h4 {
    font-size: 20px;
    line-height: 26px;
}


h3, .h3,
h4, .h4 {
    &.hl-underline {
        padding-bottom: 20px;
        margin-bottom: 20px;    
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 50px;
            height: 1px;
            background: $light-brown;
        }
    }    
}

.frame header {
    h3, .h3,
    h4, .h4 {
        &.hl-underline {
            padding-bottom: 20px !important;
            margin-bottom: 20px !important;    
        }   
    }     
}


.frame-special-container,
.frame-background-primary {
    h3, .h3,
    h4, .h4 {
      &.hl-underline {
        &:after {
          background: $glacier;
        }
      }
    }        
}
  
  
h5, .h5 {
  font-family: $font-family-base;
  font-size: 16px;
  font-weight: $font-weight-bold;
  margin-bottom: 20px;
}

h5.subline {
  font-size: 20px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

p {
  margin-bottom: 1.125rem;
  &.lead {
    font-size: 20px;
    font-weight: $font-weight-medium;
    line-height: 26px;
  }
}

ul.list-normal {
    list-style: none; 
    padding-left: 20px;
    margin-bottom: 1.125rem;
    li {
        font-weight: $font-weight-medium;
        margin-bottom: 20px;
        &::before {
            content: "\2022";  
            color: $light-brown;
            font-weight: bold; 
            display: inline-block; 
            width: 20px; 
            margin-left: -20px; 
        }
        &:last-child {
          margin-bottom: 0;
        }
    }
}

.frame-background-secondary {
    ul li::before {
        
    }    
}

ul.list-checkmark {
  @include reset-list; 
  font-family: $headings-font-family;  
  font-size: 20px;
  font-weight: $font-weight-bold;  
  
  li {
      padding: 4px 0 4px 56px;      
      background: transparent url(../images/icon-checkmark.svg) no-repeat top left;
      background-size: 36px 38px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
  }
}

.text-primary {
  color: $primary;
}

.text-secondary {
  color: $secondary;
}

.text-glacier {
  color: $glacier;
}

.text-brown {
  color: $brown;
}

.text-light-brown {
  color: $light-brown;
}

a[href^="tel:"] {
  color: $primary;
  text-decoration: none;
}

.frame-background-primary {
  a[href^="tel:"] {
    color: #fff;
    text-decoration: none;
  }  
}

.uc-embed .uc-consent-info-content {
  margin-bottom: 2.5rem !important;
}

.frame-type-text hr {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}