.card {
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    position: relative;
    .card-body {
        p:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }        
    a {
        .card-img-top {
            height: auto;
        }
        .card-body {
            color: $body-color;
        }
        .card-overlay {
            @include transition($all-transition);
            opacity: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($primary, .85);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-radius: $card-border-radius;
            color: $beige;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: $font-weight-bold;                
            .overlay-icon {
                height: 70px;
                width: 70px;
                border-radius: 50%;
                border: 2px solid $beige;
                color: $beige;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                svg.icon-external-link {
                    width: 48px;
                    height: 32px;
                }
                svg.icon-advisor_detail {
                    width: 42px;
                    height: 32px;
                }
                svg.icon-advisors {
                    width: 49px;
                    height: 32px;
                }
            }                
        }
        &:hover {
            .card-overlay {                        
                opacity: 1;
            }    
        }
    }
}