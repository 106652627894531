.tab-wrap {
    @extend .row;
    .tab-navigation {
        @extend .col-12;
        @extend .col-lg-4;
        border-bottom: 0;
        flex-direction: column;        
        margin-bottom: 40px;
        @include media-breakpoint-up(lg) {
            margin-top: -8px;
            margin-bottom: 0px;
        }
        li.nav-item {
            padding: 0;
            a.nav-link {
                padding: 8px 0;
                border-bottom: 1px solid $silvergrey;
                font-weight: $font-weight-bold;
                font-size: 14px;
                color: $silvergrey;
                &:hover {
                    color: $primary;
                }
                &.active {
                    color: $primary;
                    border-bottom: 1px solid $primary;                    
                }
            }                        
        }
    }
    .tab-content {
        @extend .col-12;
        @extend .col-lg-8;
        h4 {
            margin-bottom: 20px;
        }
    }
}
