.frame-type-counter {
    color: $beige;
    .frame-content {
        @extend .row;
        h2 {
            color: $beige;
            font-size: 28px;
        }
        .counter-header,
        .counter-item {
            @extend .col;
            @include media-breakpoint-down(md) {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
        .counter-header {
            text-align: center;
            @include media-breakpoint-up(lg) {
                text-align: left;
            }
        }
        .counter-item {
            @include media-breakpoint-down(md) {
                max-width: 100%;
                flex: 0 0 100%;
                margin-top: 50px;
            }
        }
        .counter {
            text-align: center;
            font-size: 48px;
            line-height: 60px;
            font-size: $headings-font-family;
            font-weight: $font-weight-bold;
            margin-bottom: 5px;
        }
        .counter-text {
            text-align: center;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            color: $glacier;
        }
    }
}
